import * as S from './Button.styled'

export interface ButtonProps {
  text?: string
  onClick?: () => void
  variant?: 'primary' | 'secondary' | 'danger'
  disabled?: boolean
  height?: number
  width?: number
  fontSize?: number | string
  hidden?: boolean
  icon?: React.ReactElement
  iconPosition?: 'before' | 'after'
  iconOrientation?: 'column' | 'row'
  style?: SCProps<typeof S.Container>
}

export function Button({
  text,
  onClick,
  disabled = false,
  height,
  width,
  fontSize,
  icon,
  variant = 'primary',
  iconPosition = 'before',
  iconOrientation,
  hidden = false,
  style
}: ButtonProps) {
  return (
    <S.Container
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      opacity={hidden ? 0 : 1}
      h={height}
      w={width}
      {...style}
    >
      <S.Content flexDirection={iconOrientation || 'column'}>
        {icon && iconPosition === 'before' && icon}
        <S.Text fontSize={fontSize}>{text}</S.Text>
        {icon && iconPosition === 'after' && icon}
      </S.Content>
    </S.Container>
  )
}

Button.Container = S.Container
Button.Content = S.Content
Button.Text = S.Text
