import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Searchbar } from 'src/components'
import { Page } from 'src/containers'

import * as S from './Home.styled'

export const Home = () => {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = React.useState<string>('')

  const handleSearch = async () => {
    if (searchQuery === '') return

    navigate(`/session/${searchQuery}`)
  }

  return (
    <Page>
      <S.Container style={{ maxWidth: 567 }}>
        <S.Title>Track and extend your session</S.Title>
        <Searchbar style={{ marginTop: 24 }} onSearch={(term) => setSearchQuery(term)} />
        <Button style={{ marginTop: 24 }} text="Search" onClick={handleSearch} />
      </S.Container>
    </Page>
  )
}
