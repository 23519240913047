import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    @font-face {
		font-family: "UberMoveLight";
		src: local("UberMoveLight"), url(/fonts/UberMoveLight.otf) format("opentype");
	}

	@font-face {
		font-family: "UberMoveMedium";
		src: local("UberMoveMedium"), url(/fonts/UberMoveMedium.otf) format("opentype");
	}

	@font-face {
		font-family: "UberMoveBold";
		src: local("UberMoveBold"), url(/fonts/UberMoveBold.otf) format("opentype");
	}

	html {
		overflow: hidden;
		font-family: "UberMoveMedium", sans-serif;
	}

	body {
		margin: 0;
		background-color: mainBackground;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span {
		margin: 0;
		font-family: "UberMoveMedium", sans-serif;
	}

	span {
		font-family: "UberMoveLight", sans-serif;
	}

	iframe {
		display: none;
	}

`
