import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { CustomThemeProvider } from './context/CustomThemeContext'
import { GlobalStyles } from './GlobalStyles'
import { Home, Session } from './screens'

function App() {
  return (
    <CustomThemeProvider>
      <GlobalStyles />
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/session/:reference" element={<Session />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </CustomThemeProvider>
  )
}

export default App
