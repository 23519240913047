import styled, { keyframes } from '@xstyled/styled-components'

const loader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Item = styled.divBox`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  animation: ${loader} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 3px solid gray;
  border-color: accent gray gray gray;
`
Item.displayName = 'Item'

export const Loader = styled.divBox`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  ${Item}:nth-child(1) {
    animation-delay: -0.45s;
  }

  ${Item}:nth-child(2) {
    animation-delay: -0.3s;
  }

  ${Item}:nth-child(3) {
    animation-delay: -0.15s;
  }
`
Loader.displayName = 'Loader'
