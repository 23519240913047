export const defaultColors = {
  'yellow-faded': '#daaf022b',
  'yellow-dim': '#daaf0257',
  yellow: '#daae02',
  'yellow-dark': '#b49000',
  'yellow-darker': '#886d00',
  'orange-dim': '#da7c0259',
  'brown-dim': '#3f240152',
  brown: '#3f2401',
  'green-faded': '#0ec8361a',
  'green-dim': '#0ec8367a',
  'green-pale': '#0ec836a2',
  green: '#0ec837',
  'green-dark': '#06a328',
  'green-darker': '#017c1b',
  'light-gone': '#f6f6f60a',
  'light-faded': '#f6f6f612',
  'light-dimmer': '#ffffff31',
  'light-dim': '#ffffffb0',
  light: '#f6f6f6',
  lightGray: '#e2e2e2',
  'gray-faded': '#c5c5c538',
  'gray-dimmer': '#c5c5c570',
  'gray-dim': '#c5c5c5a1',
  'gray-lighter': '#f3f3f3',
  'gray-light': '#e2e2e2',
  grey5: '#fafafa',
  grey10: '#f5f5f5',
  grey20: '#eeeeee',
  grey30: '#e0e0e0',
  grey40: '#bdbdbd',
  grey50: '#9e9e9e',
  grey60: '#757575',
  grey70: '#616161',
  grey80: '#424242',
  grey90: '#212121',
  grey100: '#000000',
  gray: '#c5c5c5',
  'gray-mid': '#c0c0c0',
  grayer: '#707070',
  darkGray: '#595858',
  darkerGray: '#404040',
  'dark-dim': '#2f2f2f',
  dark: '#2f2f2f',
  'darker-dim': '#141414ec',
  darker: '#141414',
  white: '#fff',
  'blue-faded': '#0095ff1e',
  'blue-dim': '#0095ff8f',
  blue: '#0094ff',
  'blue-dark': '#006bb8',
  'blue-darker': '#00508a',
  'purple-faded': '#c159bf18',
  'purple-dim': '#c159b51',
  purple: '#c159bf',
  'purple-dark': '#2a2a39',
  'purple-darker': '#31333f',
  'purple-dark-dim': '#4144549f',
  'purple-dark-faded': '#4144545b',
  'purple-dark-gone': '#41445412',
  'danger-dim': '#eb092f55',
  danger: '#df082c',
  failure: '#df082c',
  'magenta-dim': '#e8095455',
  magenta: '#e80954',
  orange: '#ff9d00',
  'danger-dark': '#c50024',
  'danger-darker': '#96001b',
  warning: '#f6cf2a',
  success: '#02b848',
  teal: '#02b88d',
}

export const lightColors = {
  ...defaultColors,
  'primary-faded': '#dcdcdc',
  primary: '#ffffff',
  'primary-dark': '#f5f5f5',
  'secondary-faded': '#2b2b2b',
  secondary: '#101010',
  'secondary-dark': '#0a0a0a',
  accent: '#179b6a',
  'accent-dim': '#179b6a15',
  'accent-dark': '#0f7d4d',
  mainBackground: '#f1f2f1',
  scrollPickerFadeArea: '#ececec9d',
  modalBackground: '#f1f2f1',
  modalBackdrop: '#454545df',
}

export const darkColors = {
  ...defaultColors,
  'primary-faded': lightColors['secondary-faded'],
  primary: lightColors['secondary'],
  'primary-dark': lightColors['secondary-dark'],
  'secondary-faded': lightColors['primary-faded'],
  secondary: lightColors['primary'],
  'secondary-dark': lightColors['primary-dark'],
  accent: '#daae02',
  'accent-dark': '#b49000',
  mainBackground: '#2a2a2a',
  scrollPickerFadeArea: '#000000e0',
  modalBackground: '#060606',
  modalBackdrop: '#454545df',
}
