import styled from '@xstyled/styled-components'

export const Container = styled.divBox`
  display: flex;
  width: 96%;
  height: 64px;
  border-radius: 20px;
  box-shadow: normal;
`
Container.displayName = 'Container'

export const Input = styled.inputBox`
  width: inherit;
  height: 56px;
  padding: 5px 60px;
  border-radius: 20px;
  font-weight: 500;
  cursor: pointer;
  border: 0px solid;
  font-size: 1.2rem;
  color: secondary;
  background-color: primary;
  font-family: 'UberMoveBold', sans-serif;
  text-align: center;
  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: 'UberMoveLight', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'UberMoveLight', sans-serif;
  }
`
Input.displayName = 'Input'
