import React from 'react'
import { HashLoader } from 'react-spinners'

import * as S from './OverlaySpinner.styled'

type OverlaySpinnerProps = Partial<React.ComponentProps<typeof HashLoader>>

export const OverlaySpinner = ({ loading = false, color = '#179b6a', size = 60, ...rest }: OverlaySpinnerProps) => {
  return (
    <S.OverlaySpinner data-loading={loading}>
      <HashLoader loading={loading} color={color} size={size} {...rest} />
    </S.OverlaySpinner>
  )
}
