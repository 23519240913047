import axios, { AxiosRequestConfig, CancelTokenSource, Method } from 'axios'
import queryString from 'querystring'
import React, { useState } from 'react'

export type QueryString<T> = Partial<T> | Record<string, unknown>

export interface FetchRequestParams<T, V> {
  body?: T | null
  method?: Method
  timeoutMs?: number
  qs?: QueryString<V>
  overrides?: AxiosRequestConfig
  processResponse?: (res: V) => V
}

export interface AxiosFetch<T, V> {
  status: ProcessStatus
  fetch: (params?: FetchRequestParams<T, V>) => Promise<V>
}

function removeUndefinedFields(obj?: any): any {
  if (!obj) return {}

  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      newObj[key] = obj[key]
    }
  })
  return newObj
}

export function useAxiosFetch<Req, Res>(
  methodType: Method,
  url: Nullable<string>,
  processResponse?: (res: Res) => Res
): AxiosFetch<Req, Res> {
  const [status, setStatus] = useState<ProcessStatus>('sleeping')

  const source = React.useRef<CancelTokenSource>()

  React.useEffect(
    () => () => {
      if (status === 'processing') source.current?.cancel()
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const fetch = async (props?: FetchRequestParams<Req, Res>): Promise<Res> => {
    const { body, method, qs, overrides, timeoutMs = 10000, processResponse: processResponseOverride } = props || {}

    try {
      setStatus('processing')
      source.current = axios.CancelToken.source()

      const timeout = setTimeout(() => {
        source.current?.cancel()
      }, timeoutMs)

      const validatedQs = removeUndefinedFields(qs)

      const request = axios({
        method: method || methodType,
        data: body || undefined,
        url: `${qs ? `${url}?${queryString.stringify(validatedQs as any)}` : url}`,
        cancelToken: source.current?.token,
        ...overrides
      })

      const { data: res } = await request
      clearTimeout(timeout)
      setStatus('success')

      return (processResponseOverride?.(res) || processResponse?.(res)) ?? res
    } catch (err: any) {
      if (err?.response?.status === 403) setStatus('res_forbidden')
      else if (err?.response?.status === 404) setStatus('res_missing')
      else setStatus('failed')

      throw err
    }
  }

  return { fetch, status }
}
