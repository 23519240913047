import { defaultTheme } from '@xstyled/styled-components'

import { darkColors, lightColors } from './colors'

export const theme = {
  ...defaultTheme,
  screens: {
    _: 0,
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1536px',
    small: '767px',
    medium: '1100px',
    large: '1500px',
    xlarge: '2500px',
  },
  space: {
    0: '0px',
    5: '5px',
    10: '10px',
    15: '15px',
    20: '20px',
    50: '50px',
  },
  fontSizes: {
    xxxlarge: 50,
    xxlarge: 40,
    xlarge: 30,
    large: 25,
    medium: 20,
    small: 15,
    xsmall: 10,
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  colors: darkColors,
  shadows: {
    light: '0px 4px 10px rgba(16, 17, 68, 0.2)',
    'light-reverse': '0px -4px -10px rgba(16, 17, 68, 0.2)',
    normal: '0px 4px 10px rgba(0, 0, 0, 0.4)',
  },
} as const

export const getCustomTheme = ({ mode = 'light' }: { mode?: 'light' | 'dark' }) => {
  return {
    ...defaultTheme,
    screens: {
      _: 0,
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1536px',
      small: '767px',
      medium: '1100px',
      large: '1500px',
      xlarge: '2500px',
    },
    space: {
      0: '0px',
      5: '5px',
      10: '10px',
      15: '15px',
      20: '20px',
      50: '50px',
    },
    fontSizes: {
      xxxlarge: 50,
      xxlarge: 40,
      xlarge: 30,
      large: 25,
      medium: 20,
      small: 15,
      xsmall: 10,
    },
    fontWeights: {
      light: 300,
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    colors: mode === 'dark' ? darkColors : lightColors,
    shadows: {
      light: '0px 4px 10px rgba(16, 17, 68, 0.2)',
      'light-reverse': '0px -4px -10px rgba(16, 17, 68, 0.2)',
      normal: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    },
  } as const
}

export type Theme = typeof theme
