import styled from '@xstyled/styled-components'

export const OverlaySpinner = styled.divBox`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all 1s linear;
  transition-duration: 1s;

  &[data-loading='true'] {
    pointer-events: all;
    opacity: 1;
    cursor: progress;
  }

  span {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
OverlaySpinner.displayName = 'OverlaySpinner'
