import styled from '@xstyled/styled-components'

export const Container = styled.divBox`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
`
Container.displayName = 'Container'

export const Title = styled.pBox`
  width: 100%;
  font-weight: 500;
  font-size: large;
  text-align: center;
`
Title.displayName = 'Title'

export const Subtitle = styled.spanBox`
  width: 100%;
  font-weight: 400;
  font-size: medium;
  text-align: center;
`
Subtitle.displayName = 'Subtitle'
