import styled from '@xstyled/styled-components'

export const Container = styled.divBox`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -moz-available; /* WebKit-based browsers will ignore this. */
  max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  max-height: fill-available;
  overflow: hidden;
`
Container.displayName = 'Container'

export const Shell = styled.divBox`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  position: relative;
  overflow: scroll;
`
Shell.displayName = 'Shell'
