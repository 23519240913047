import { isAxiosError } from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AxiosFetch, FetchRequestParams, QueryString, useAxiosFetch } from './useAxiosFetch'

type DataFetch<T> = [
  state: {
    data?: T
    setData: Dispatch<SetStateAction<T | undefined>>
    status: ProcessStatus
    error?: any
  } & Pick<AxiosFetch<null, T>, 'status'>,
  fetch: (params?: FetchParams<T>) => Promise<Nullable<T>>
]

type FetchParams<V> = Pick<FetchRequestParams<null, V>, 'qs' | 'overrides' | 'processResponse'>

export function useData<T>(
  url: Nullable<string>,
  fetchOnMount = true,
  qs?: Nullable<QueryString<T>>,
  rest?: Pick<FetchRequestParams<null, T>, 'overrides' | 'processResponse'>
): DataFetch<T> {
  const [data, setData] = useState<T>()
  const { fetch: axiosFetch, status } = useAxiosFetch<null, T>('GET', url)
  const [error, setError] = useState<any>()

  const fetch = async (overrideParams?: FetchParams<T>): Promise<Nullable<T>> => {
    setError(undefined)
    const { qs: overrideQs, overrides, processResponse: processResponseOverride } = overrideParams || {}

    try {
      const res = await axiosFetch({
        qs: overrideQs ? { ...overrideQs } : { ...qs },
        overrides: overrides || rest?.overrides,
        processResponse: processResponseOverride || rest?.processResponse
      })
      setData(res)

      return res
    } catch (err) {
      if (isAxiosError(err)) {
        setError(err.response?.data)
      } else {
        setError(err)
      }
      return null
    }
  }

  useEffect(() => {
    if (fetchOnMount) fetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [{ data, setData, status, error }, fetch]
}
