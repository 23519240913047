import { OverlaySpinner, TopBar, TopBarProps } from 'src/components'

import * as S from './Page.styled'

interface PageProps {
  children: React.ReactElement | React.ReactElement[]
  topBarProps?: TopBarProps
  loading?: boolean
  style?: React.CSSProperties
}

export function Page({ children, topBarProps, loading, style }: PageProps) {
  return (
    <S.Container>
      <TopBar {...topBarProps} />
      <OverlaySpinner loading={loading} />
      <S.Shell style={style}>{children}</S.Shell>
    </S.Container>
  )
}
