import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React from 'react'
import { isIOS } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'
import { Icons } from 'src/assets'
import { Button, Condition } from 'src/components'
import { DATE_FORMATS } from 'src/constants/dateFormats'
import { Page } from 'src/containers'
import { useAxiosFetch } from 'src/hooks/useAxiosFetch'
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard'
import { useData } from 'src/hooks/useData'
import { Utils } from 'src/utils'

import * as S from './Session.styled'
import { x } from '@xstyled/styled-components'

export const Session = () => {
  const navigate = useNavigate()
  const { reference } = useParams()

  const [{ data: booking, status: permitFetchStatus, error }] = useData<FullBookingResponse | null>(
    `/api/bookings/${reference}`,
    true,
    {
      include: Utils.genQueryParams({
        sessions: {
          include: {
            bay: true,
            transaction: { select: { amount: true, status: true, type: true, id: true } }
          }
        },
        location: { select: { name: true } }
      })
    }
  )

  const isBookingAssignedToCustomerError = error?.code === 'BOOKING_REEDEMED_IN_APP'

  return (
    <Page loading={permitFetchStatus === 'processing'}>
      <S.Container className="PageContainer">
        <Condition
          when={
            !isBookingAssignedToCustomerError && (['failed', 'unknown'] as ProcessStatus[]).includes(permitFetchStatus)
          }
        >
          <S.ErrorMessage>Something went wrong. Please reload the page</S.ErrorMessage>
        </Condition>
        <Condition when={isBookingAssignedToCustomerError}>
          <S.ErrorMessage>
            The session you're looking for is linked to a customer account. If you've already added it to your account,
            check your app history page.
          </S.ErrorMessage>
        </Condition>
        <Condition when={(['res_missing'] as ProcessStatus[]).includes(permitFetchStatus)}>
          <S.ErrorMessage>
            We couldn't find a session associated with reference #{reference}. Please make sure you've entered the
            correct code.
          </S.ErrorMessage>
        </Condition>
        <Condition
          when={(['failed', 'unknown', 'res_missing', 'res_forbidden'] as ProcessStatus[]).includes(permitFetchStatus)}
        >
          <Button style={{ marginTop: 24 }} text="Search again" onClick={() => navigate('/')} />
        </Condition>

        <Condition when={permitFetchStatus === 'success'}>
          <BookingDetailsSection booking={booking} />
          <AppSection
            ongoing={dayjs(booking?.startDate).isBefore(dayjs()) && dayjs(booking?.endDate).isAfter(dayjs())}
          />
          <CostBreakDownSection sessions={booking?.sessions} />
          <PaymentBreakdownSection sessions={booking?.sessions} />

          <ActionsSection booking={booking as FullBookingResponse} />
        </Condition>
      </S.Container>
    </Page>
  )
}

const AppSection = ({ ongoing }: { ongoing: boolean }) => {
  const title = React.useRef(ongoing ? 'Running out of time?' : 'Enjoy more features').current
  const subtitle = React.useRef(
    ongoing ? 'Get the app to extend your session.' : 'Get the app to book and extend your future sessions.'
  ).current
  const ctaText = React.useRef(ongoing ? 'Extend session' : 'Add session to app').current

  return (
    <Section title={title}>
      <S.Text>{subtitle}</S.Text>
      <S.Row style={{ marginTop: 16, justifyContent: 'center', width: 'inherit' }}>
        <Button
          style={{ w: 'inherit' }}
          text={ctaText}
          onClick={() => {
            const platformUrl = isIOS
              ? 'https://apple.co/3yUcPnH'
              : 'https://play.google.com/store/apps/details?id=com.twinpay'
            window.open(platformUrl, '_blank')
          }}
        />
      </S.Row>
    </Section>
  )
}

const DownloadReceiptButton = ({ bookingRef, disabled }: { bookingRef: string; disabled: boolean }) => {
  const { fetch: fetchReceipt } = useAxiosFetch('GET', `/api/receipts/${bookingRef}`)

  const { enqueueSnackbar: toast } = useSnackbar()

  const dispatchDownloadingReceiptToast = () => {
    toast(`Downloading receipt...`, {
      variant: 'default',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
    })
  }

  return (
    <Button
      style={{ marginTop: 16, w: 'inherit', pointerEvents: disabled ? 'none' : 'auto' }}
      text="Download receipt"
      onClick={async () => {
        dispatchDownloadingReceiptToast()

        const receipt = (await fetchReceipt({ overrides: { responseType: 'blob' } })) as any
        const url = window.URL.createObjectURL(new Blob([receipt], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `receipt_${bookingRef}.pdf`)
        document.body.appendChild(link)
        link.click()
      }}
      disabled={disabled}
      variant="secondary"
    />
  )
}

const ActionsSection = ({ booking }: { booking: FullBookingResponse }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar: toast } = useSnackbar()

  const isParkAndChargeSession = booking?.sessions?.some((s) => s.type === 'charging')
  const bookingHasEnded = !!booking?.endDate && dayjs(booking?.endDate).isBefore(dayjs())
  const bookingHasStarted = !!booking?.startDate && dayjs(booking?.startDate).isBefore(dayjs())

  const canDownloadReceipt = isParkAndChargeSession ? bookingHasEnded : bookingHasStarted

  const dispatchUnableToDownloadReceiptToast = () => {
    toast(`The receipt for this booking is not yet available. Please try again later.`, {
      variant: 'default',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
    })
  }

  return (
    <>
      <x.div w={1} onClick={() => !canDownloadReceipt && dispatchUnableToDownloadReceiptToast()}>
        <DownloadReceiptButton bookingRef={booking?.reference} disabled={!canDownloadReceipt} />
      </x.div>

      <Button style={{ marginTop: 16, w: 'inherit' }} text="Find another session" onClick={() => navigate('/')} />
    </>
  )
}

const BookingDetailsSection = ({ booking }: { booking?: Nullable<FullBookingResponse> }) => {
  const chargingSession = React.useRef<IChargingSession | undefined>(
    booking?.sessions?.find((s) => s.type === 'charging') as IChargingSession
  ).current

  const { enqueueSnackbar: toast } = useSnackbar()
  const copyToClipboard = useCopyToClipboard()

  return (
    <Section
      title={`Session #${booking?.reference}`}
      toolbar={
        <Icons.CopyAllIcon
          width={28}
          height={28}
          onClick={async () => {
            try {
              await copyToClipboard(booking?.reference as string)

              toast(`${booking?.reference} copied to clipboard`, {
                variant: 'default',
                autoHideDuration: 3000,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
              })
            } catch (error) {
              toast(`Couldn't copy to clipboard`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
              })
            }
          }}
        />
      }
      style={{ cursor: 'pointer', userSelect: 'text' }}
    >
      <S.Row style={{ justifyContent: 'flex-start' }}>
        <S.Icon src={'/vrm.svg'} alt="vrm" h={22} />
        <S.Text style={{ marginLeft: 8 }}>{booking?.vrm}</S.Text>
      </S.Row>
      <S.Row style={{ marginTop: 4, justifyContent: 'flex-start' }}>
        <S.Icon src={'/place.svg'} alt="location" h={22} />
        <S.Text style={{ marginLeft: 8 }}>{booking?.location?.name}</S.Text>
      </S.Row>
      <Condition when={!!chargingSession && Boolean(chargingSession?.bay)}>
        <S.Row style={{ marginTop: 4, justifyContent: 'flex-start' }}>
          <S.Icon src={'/charging_bay.png'} alt="bay" />
          <S.Text style={{ marginLeft: 8 }}>{chargingSession?.bay?.name}</S.Text>
        </S.Row>
      </Condition>
    </Section>
  )
}

const CostBreakDownSection = ({ sessions }: { sessions?: Array<IChargingSession | IParkingSession> }) => {
  const totalCost = React.useRef(sessions?.reduce((total, s) => total + Number(s.cost), 0) || 0).current

  return (
    <Section title="Cost breakdown">
      {sessions?.map((session, index) => (
        <SessionCostBreakdown key={index} session={session} style={{ alignItems: 'flex-start', marginBottom: 8 }} />
      ))}
      <BreakdownRow title="Subtotal" amount={totalCost} />
    </Section>
  )
}

const PaymentBreakdownSection = ({ sessions }: { sessions?: Array<IChargingSession | IParkingSession> }) => {
  const uniqueTransactions: Array<IBookingSession['transaction']> =
    sessions?.reduce((uniqueSet, s) => {
      if (uniqueSet.findIndex((t) => t?.id === s.transactionId) === -1) uniqueSet.push(s.transaction)

      return uniqueSet
    }, [] as Array<IBookingSession['transaction']>) || []

  const paidAmount = React.useRef(
    uniqueTransactions?.reduce((total, t) => {
      const isImmutableTransaction = (['CAPTURE', 'CHARGE'] as TransactionType[]).includes(t?.type as TransactionType)
      return isImmutableTransaction && t?.status === 'SUCCESSFUL' ? total + t.amount : total
    }, 0) || 0
  ).current

  const authorisedAmount = React.useRef(
    uniqueTransactions?.reduce((total, t) => {
      const isAuthTransaction = (['AUTH', 'PREAUTH'] as TransactionType[]).includes(t?.type as TransactionType)
      return isAuthTransaction && t?.status === 'SUCCESSFUL' ? total + t.amount : total
    }, 0) || 0
  ).current

  const totalCost = React.useRef(sessions?.reduce((total, s) => total + Number(s.cost), 0) || 0).current

  return (
    <Section title="Payment breakdown">
      <Condition when={!!paidAmount}>
        <BreakdownRow title="Amount paid" amount={paidAmount} />
      </Condition>
      <Condition when={!!authorisedAmount}>
        <BreakdownRow title="Amount authorised" amount={authorisedAmount} style={{ marginTop: 8 }} />
        <S.Divider style={{ marginTop: 8 }} />
        <BreakdownRow
          title="Charging balance left"
          amount={paidAmount + authorisedAmount - totalCost}
          style={{ marginTop: 8 }}
        />
      </Condition>
    </Section>
  )
}

const Section = ({
  children,
  toolbar,
  title,
  style,
  onPress
}: {
  children?: Children
  toolbar?: React.ReactNode
  title?: string
  style?: SCProps<typeof S.Section>
  onPress?: () => void
}) => {
  return (
    <S.Section {...style} onClick={() => onPress?.()}>
      <S.Row style={{ marginBottom: 8 }}>
        {title && <S.Title>{title}</S.Title>}
        {toolbar && toolbar}
      </S.Row>
      {children}
    </S.Section>
  )
}

const SessionCostBreakdown = ({
  session,
  style
}: {
  session: IChargingSession | IParkingSession
  style: SCProps<typeof S.Row>
}) => {
  const [title, setTitle] = React.useState('')
  const [subtitle, setSubtitle] = React.useState('')

  React.useEffect(() => {
    if (session.type === 'charging') {
      const chargedKWh = Number((session as IChargingSession).chargedKWh).toFixed(2)
      setTitle(`Charged ~${chargedKWh} kWh`)
    } else {
      setTitle(session.type === 'parking_premium' ? 'EV overstay parking' : 'Standard Parking')
    }

    const formattedStartDate = session?.startDate ? dayjs(session?.startDate).format(DATE_FORMATS.DATE_TIME) : 'n/a'
    const formattedEndDate = session?.endDate ? dayjs(session?.endDate).format(DATE_FORMATS.DATE_TIME) : 'n/a'
    setSubtitle(`${formattedStartDate} - ${formattedEndDate}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <BreakdownRow title={title} subtitle={subtitle} amount={session.cost} style={style} />
}

const BreakdownRow = ({
  title,
  subtitle,
  amount,
  style
}: {
  style?: SCProps<typeof S.Row>
  title: string
  subtitle?: string
  amount?: number
}) => {
  return (
    <S.Row {...style}>
      <S.Col>
        <S.Text>{title}</S.Text>
        <S.Label>{subtitle}</S.Label>
      </S.Col>
      <Condition when={amount != null}>
        <S.Col>
          <S.Text>£{Number(amount).toFixed(2)}</S.Text>
        </S.Col>
      </Condition>
    </S.Row>
  )
}

interface IStatusState {
  parking_premium: string
  parking_regular: string
  charging: string
  color?: string
}

const ONGOING: IStatusState = {
  parking_premium: 'EV overstay parking',
  parking_regular: 'Standard parking',
  charging: 'Charging'
}

const COMPLETED: IStatusState = {
  parking_premium: 'Parking ended',
  parking_regular: 'Parking ended',
  charging: 'Charging ended'
}

export const SessionStatus = ({
  sessionType,
  startDate,
  endDate,
  variant = 'extended',
  style
}: {
  sessionType: SessionType
  startDate: string
  endDate: string
  variant?: 'short' | 'extended'
  style?: SCProps<typeof S.Row>
}) => {
  const isOngoing = React.useRef(dayjs(endDate).isAfter(dayjs())).current // TODO take into consideration startDate as well
  const title = React.useRef(isOngoing ? ONGOING[sessionType] : COMPLETED[sessionType]).current

  return (
    <S.Row {...style}>
      <S.Col>
        <S.Text>{title || 'n/a'}</S.Text>
        <Condition when={variant === 'extended'}>
          <Condition when={isOngoing}>
            <S.Label>{`Until ${dayjs(endDate).format(DATE_FORMATS.DATE_TIME)}`}</S.Label>
          </Condition>
          <Condition when={!isOngoing}>
            <S.Label>{dayjs(endDate).format(DATE_FORMATS.DATE_TIME)}</S.Label>
          </Condition>
        </Condition>
      </S.Col>
    </S.Row>
  )
}
