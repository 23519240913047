export const useCopyToClipboard = () => {
  const copyToClipboard = async (text: string | number) => {
    if (window.isSecureContext) {
      await navigator.clipboard.writeText(String(text))
    } else {
      var textField = document.createElement('textarea')
      textField.innerText = String(text)
      document.body.appendChild(textField)
      textField.setSelectionRange(0, 99999)
      textField.select()
      document.execCommand('copy') // TODO this is deprecated
      textField.remove()
    }
  }

  return copyToClipboard
}
