import styled, { css, system } from '@xstyled/styled-components'

import { variant } from 'src/utils/style'

const variants = {
  none: {},
  primary: css`
    outline: none !important;

    background-color: accent;
    border-color: transparent;
    color: primary;

    &:hover {
      background-color: accent-dark;
    }

    &:disabled {
      background-color: grey;
    }
  `,
  secondary: css`
    outline: none !important;

    background-color: secondary;
    border-color: transparent;
    color: primary;

    &:disabled {
      background-color: grey;
    }
  `,
  danger: css`
    outline: none !important;

    background-color: danger;
    border-color: transparent;
    color: primary;
  `
}

const buttonVariant = variant({
  default: 'primary',
  variants
})
type ButtonVariantProps = { readonly variant?: keyof typeof variants }

export const Container = styled.buttonBox<ButtonVariantProps>`
  height: 64px;
  padding: 5px 60px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid;
  font-size: 1.2rem;
  color: secondary;
  background-color: primary;
  font-family: 'UberMoveLight', sans-serif;
  box-shadow: normal;
  outline: none !important;

  transition: all 0.5s ease-in-out;

  && {
    ${buttonVariant}
    ${system}
  }
`
Container.displayName = 'Container'

export const Content = styled.divBox`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
Content.displayName = 'Content'

export const Text = styled.pBox`
  font-family: 'UberMoveLight', sans-serif;
`
Text.displayName = 'Text'
