import styled, { system } from '@xstyled/styled-components'

import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100px;
  background-color: white;
  box-shadow: light;
  padding: 0 24px;
  box-sizing: border-box;
`

export const $Logo = styled.imgBox`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  height: 60px;
  width: 80px;

  object-fit: contain;

  ${Styles.screens.small`
    width: 80px;
  `}

  ${Styles.screens.medium`
    width: 90px;
  `}

  ${Styles.screens.large`
    width: 100px;
  `}
`
export const $Title = styled.h2Box`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: calc(100% - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  text-align: center;
  font-size: 20px;
  font-family: 'UberMoveLight', sans-serif;
  font-weight: bold;
  color: secondary;
  opacity: 0;

  transition: all 0.3s ease-in-out;

  ${Styles.screens.large`
    font-size: 45px;
  `}

  ${Styles.screens.medium`
    font-size: xlarge;
  `}

  ${Styles.screens.small`
    font-size: 25px;
  `}

  ${system}
`
