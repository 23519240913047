export const clearEmpties = (o) => {
  if (!o || Object.entries(o).length === 0) {
    return
  }

  const clone = { ...o }

  for (const [key, value] of Object.entries(o)) {
    if (!value) delete clone[key]

    if (typeof value === 'object') {
      const temp = clearEmpties(value)
      if (!temp || Object.entries(temp).length === 0) delete clone[key]
    }
  }

  return clone
}

export const genQueryParams = (fields: { [key: string]: unknown }): string => {
  const cleanedFields = clearEmpties(fields)

  return encodeURIComponent(JSON.stringify(cleanedFields))
}
