import styled from '@xstyled/styled-components'

export const Container = styled.divBox`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 128px;
  width: 100%;
  max-width: 567px;
`
Container.displayName = 'Container'

export const Title = styled.pBox`
  width: 100%;
  font-weight: 500;
  font-size: large;
`
Title.displayName = 'Title'

export const ErrorMessage = styled.pBox`
  width: 100%;
  font-weight: 400;
  font-size: medium;
  text-align: center;
  color: failure;
`
ErrorMessage.displayName = 'ErrorMessage'

export const Section = styled.divBox`
  width: 100%;
  margin-bottom: 24px;
`
Section.displayName = 'Section'

export const Row = styled.divBox`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
Row.displayName = 'Row'

export const Col = styled.divBox`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
Col.displayName = 'Col'

export const Text = styled.pBox`
  font-weight: 500;
  font-size: medium;
  font-family: 'UberMoveLight', sans-serif;
`
Text.displayName = 'Text'

export const Label = styled.pBox`
  font-weight: 500;
  font-size: small;
  font-family: 'UberMoveLight', sans-serif;
  color: darkGray;
`
Label.displayName = 'Label'

export const Divider = styled.divBox`
  width: inherit;
  height: 1px;
  background: lightGray;
`
Divider.displayName = 'Divider'

export const Icon = styled.imgBox`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  height: 26px;
  width: 26px;

  object-fit: contain;
`
Icon.displayName = 'Icon'
