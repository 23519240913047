import styled from '@xstyled/styled-components'

export const StoreBadge = styled.imgBox`
  position: relative;
  width: 140px;
  border-radius: 8px;
  object-fit: contain;
  box-shadow: light;
  background-color: transparent;
`

StoreBadge.displayName = 'StoreBadge'
