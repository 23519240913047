import { AndroidView, IOSView } from 'react-device-detect'
import * as S from './StoreBadge.styled'

export const StoreBadge = (style?: SCProps<typeof S.StoreBadge>) => {
  return (
    <>
      <IOSView>
        <S.StoreBadge
          {...style}
          src="/app_store_badge.svg"
          alt="Download on the App Store"
          onClick={() => window.open('https://apple.co/3yUcPnH', '_blank')}
        />
      </IOSView>
      <AndroidView>
        <S.StoreBadge
          {...style}
          src="/google_play_badge.png"
          alt="Download on Google Play"
          onClick={() => window.open('https://play.google.com/store/apps/details?id=com.twinpay', '_blank')}
        />
      </AndroidView>
    </>
  )
}
