import * as S from './Searchbar.styled'
export interface SearchbarProps {
  onSearch?: (searchTerm: string) => void
  searchPlaceholder?: string
  style?: SCProps<typeof S.Container>
}

export function Searchbar({ onSearch, searchPlaceholder = 'Input reference number', style }: SearchbarProps) {
  return (
    <S.Container {...style}>
      <S.Input
        placeholder={searchPlaceholder}
        onChange={({ target: { value } }) => onSearch?.(value)}
        onBlur={() => window.scrollTo(0, 0)}
      />
    </S.Container>
  )
}
