import { Link } from 'react-router-dom'
import { StoreBadge } from '../StoreBadge'
import * as S from './TopBar.styled'

export interface TopBarProps {
  text?: string
}

export function TopBar({ text }: TopBarProps) {
  return (
    <S.$Container>
      <Link to="/">
        <S.$Logo src={'/logo.png'} alt="logo" />
      </Link>
      {text && <S.$Title>{text}</S.$Title>}
      <StoreBadge />
    </S.$Container>
  )
}
